import React from 'react'
import { NavLink} from "react-router-dom";

function DystolabMenu() {
  return (
    <>
    {/* <!-- Dystolab Menu --> */}
    <div className="col-sm-12 dystolab-buttons">
      <div className="btn-group">

      {/* <!-- Dashboard Component Button --> */}
      <NavLink to="/dashboard" className="btn btn-outline-primary">Dashboard</NavLink>

      {/* <!-- Desktop "Collection Component" Button --> */}
      <NavLink to="/collection" className="btn btn-outline-primary d-none d-md-block active">Collection</NavLink>

      {/* <!-- Mobile "Collection Component" Button --> */}
      <NavLink to="/collection" className="btn btn-outline-primary d-md-none active">NFTs</NavLink>

      {/* <!-- CrateDex Component Button --> */}
      <NavLink to="/cratedex" className="btn btn-outline-primary">CrateDex</NavLink>
      </div>
    </div>
    <hr />
    </>
  )
}

export default DystolabMenu