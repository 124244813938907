import React, { useState, useEffect } from 'react';
import Spinner from './../Spinner';
import DystoPunkCard from './../DystoPunkCard';

function DystoPunkContainer({ownedDystoPunks, handleChecked}) {
    const [allLoaded, setAllLoaded] = useState(false);

    useEffect(() => {
        let allImagesLoaded = true;
        const images = document.querySelectorAll('img');
        images.forEach((img) => {
          if (!img.complete) {
            allImagesLoaded = false;
            img.addEventListener('load', handleImageLoad);
          }
        });
        if (allImagesLoaded) {
          setAllLoaded(true);
        }
        return () => {
          images.forEach((img) => {
            img.removeEventListener('load', handleImageLoad);
          });
        };
      }, []);

      const handleImageLoad = () => {
        const images = document.querySelectorAll('img');
        let allImagesLoaded = true;
        images.forEach((img) => {
          if (!img.complete) {
            allImagesLoaded = false;
          }
        });
        if (allImagesLoaded) {
          setAllLoaded(true);
        }
      };

  return (
    <>
    {!allLoaded && <Spinner />}
        {ownedDystoPunks.map((token, i) => (
            <DystoPunkCard 
            key={token}
            id={token}
            token={token.toNumber() + 1}
            handleChecked={handleChecked}
            />
        ))
        }
    </>
  )
}

export default DystoPunkContainer