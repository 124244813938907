import {React, useEffect, useState} from 'react'
import {dystoPunksVXContract, credsContract, dystolabContract, crateContract} from './contracts/allContracts.js'
import LitepaperInfo from './info/LitepaperInfo'
import DystolabInfo from './info/DystolabInfo'
import Crate from './models/Crate'
import Mascot from './models/Mascot'
import { useContractRead, useAccount } from 'wagmi'
import { useIsMounted } from "./hooks/useIsMounted";
import CrateABI from '../abi/crateABI.json'
import DystoPunksVXABI from '../abi/dystopunksvxABI.json'
import CredsABI from '../abi/credsABI.json'
import { MintVX } from './mints/mintVX';
import { BurnCrate } from './mints/BurnCrate';
import Connect from './Connect';
import ReactLoading from 'react-loading';
import ApprovalButton from './buttons/ApprovalButton';
import NoCrates from './menus/NoCrates';
import BurnOne from './buttons/BurnOne';
import BurnTen from './buttons/BurnTen';
import BurnOneHundred from './buttons/BurnOneHundred';

function Mint({cratesFloor}) {
  const mounted = useIsMounted();
  const { address, isConnected } = useAccount()
  const [totalMintedVX, setTotalMintedVX] = useState("")
  const [totalCratesBurned, setTotalCratesBurned] = useState("")
  const [approvedForBurn, setApprovedForBurn] = useState(null)
  const [totalUserCrates, setTotalUserCrates] = useState("")
  const [loading, setLoading] = useState(true)
  const [crateStatus, setCrateStatus] = useState(null)
  const [mintStatus, setMintStatus] = useState(null)

  // use to set state of approval button. If false, show button.
  // eslint-disable-next-line


  useEffect(() => {
    document.body.classList.add(
      "d-flex",
      "text-bg-dark",
      "dystolab-bg"
    );
    document
      .getElementById("root")
      .classList.add(
        "cover-container",
        "d-flex",
        "w-100",
        "h-100",
        "p-3",
        "mx-auto",
        "flex-column"
      );

    return function cleanup() {
      document.body.classList.remove(
        "d-flex",
        "text-bg-dark",
        "dystolab-bg"
      );
      document
        .getElementById("root")
        .classList.remove(
          "cover-container",
          "d-flex",
          "w-100",
          "h-100",
          "p-3",
          "mx-auto",
          "flex-column"
        );
    };
  }, []);

  const { data: totalMinted } = useContractRead({
    ...dystoPunksVXContract,
    functionName: 'minted',
    enabled: true,
    onSuccess(totalMinted) {
      setTotalMintedVX(totalMinted)
    },
  })
  const { data: totalBurned } = useContractRead({
    ...crateContract,
    functionName: 'balanceOf',
    enabled: true,
    args: ["0x000000000000000000000000000000000000dEaD", "77"],
    onSuccess(totalBurned) {
      setTotalCratesBurned(totalBurned)
    },
  })
  const { data: userCrates } = useContractRead({
    ...crateContract,
    functionName: 'balanceOf',
    enabled: true,
    args: [address, "77"],
    watch: true,
    onSuccess(userCrates) {
      setTotalUserCrates(userCrates)
    },
  })
  const { data: isApprovedForBurn } = useContractRead({
    ...crateContract,
    functionName: 'isApprovedForAll',
    enabled: true,
    watch: !approvedForBurn,
    args: [address, dystolabContract.address],
    onSuccess(isApprovedForBurn) {
      setApprovedForBurn(isApprovedForBurn)
    },
  })

  useEffect(() => {
    if(approvedForBurn !== null) {
      setLoading(false)
    }
    return () => {
      setLoading(true)
    }
  }, [approvedForBurn])

  return (
    <>
    {mounted && <>{isConnected ? <>{/* <!-- Mint Component --> */}
{/* <!-- Show only when connected, If not connected show "Connect Component" instead --> */}
<main className="main-info text-center px-3">
  <div className="container">
    <div className="row">

    {/* <!-- VX Mint --> */}
    <div className="col-md-6 mint-bg">
      <h2 className="title-primary">Mint VX</h2>
      <hr />
      {/* <img src="img/logos/credstack.png" alt="DystoPunks VX" className="img-fluid mint" /> */}
      <Mascot className="img-fluid mint" />
      <br />

      {/* <!-- Minted VX Counter --> */}
      {/* <!-- Subtract -2300 to not count Free Claims --> */}
      <p className="lead text-center"><b>{totalMintedVX?.toString()}/7777</b> Minted
      <br />
      <b className="balance">0.077Ξ</b> Each
      </p>
      
      {isConnected ? <MintVX setMintStatus={setMintStatus} />:<p>Connect wallet to mint!</p>}
      {mintStatus}
    </div>

    {/* <!-- Crates Burn --> */}
    <div className="col-md-6 mint-bg">
      <h2 className="title-primary">Burn Crates</h2>
      <hr />
      {/* <img src="img/logos/crate.png" alt="Dystolab Crate" className="img-fluid mint" /> */}
      <Crate className="img-fluid mint" />
      <br />

      {/* <!-- Burned Crates Counter --> */}
      <p className="lead text-center"><b>{totalCratesBurned?.toString()}/7777</b> Burned

      <br />

      {/* <!-- Not Connected --> */}
      {!isConnected && <>Connect wallet to open!</>}

      {/* <!-- Unopened Crates Balance --> */}
      {isConnected && <>Crate Balance: <b className="balance">{totalUserCrates?.toString()}</b></>}

      </p>

      {loading ? 
      <div style={{display: 'inline-block'}}>
        <ReactLoading type="spin" color="#78fae6" height={'24px'} width={'24px'} />
      </div> 
      :
      <>
        {totalUserCrates > 0 ?<>
        {/* <!-- Approval Button -->
        <!-- Hide when approved and show "Burn Function" --> */}
        {isConnected && !approvedForBurn && <ApprovalButton crateContract={crateContract} dystolabAddress={dystolabContract.address}/>}

        {approvedForBurn && 
        <>
          {/* <!-- Burn Function -->
          <!-- Show when approved and hide "Approval Button" --> */}
          {/* <BurnCrate totalUserCrates={totalUserCrates}/> */}
          <div className="d-none d-lg-block">
          <BurnOne totalUserCrates={totalUserCrates} setCrateStatus={setCrateStatus}/>
          <span className="ml-2 mr-2">
            <BurnTen totalUserCrates={totalUserCrates} setCrateStatus={setCrateStatus}/>
          </span>
          <BurnOneHundred totalUserCrates={totalUserCrates} setCrateStatus={setCrateStatus}/>
          {crateStatus}
          </div>
          {/* Mobile */}
          <div className="d-lg-none">
          <BurnOne totalUserCrates={totalUserCrates} setCrateStatus={setCrateStatus}/>
          <span className="ml-2">
          <BurnTen totalUserCrates={totalUserCrates} setCrateStatus={setCrateStatus}/>
          </span>
          {/* <BurnOneHundred totalUserCrates={totalUserCrates} /> */}
          {crateStatus}
          </div>
        </>
        }
        </>: <NoCrates cratesFloor={cratesFloor}/>}
      </>
      }
    </div>
    <DystolabInfo />
    <LitepaperInfo />
    </div>
  </div>
</main></>: <Connect/> }</>}
    </>
  )
}

export default Mint