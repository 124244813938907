import React from 'react'
import { NavLink} from "react-router-dom";

function DystolabInfo() {
  return (
    <>
    {/* <!-- Dystolab Info --> */}
    <div className="col-sm-12 mint-bg2">
      <p className="lead text-center">To claim <img src="img/logos/cred.gif" alt="$CREDS" className="img-fluid mint-icons" /><b>$CREDS</b>, <b>VX</b>, view your <b>DystoPunks</b> and <img src="img/logos/crate.png" alt="Crate" className="img-fluid mint-icons" /><b>CrateDex</b>, please go to&nbsp;

      {/* <!-- Dystolab App Button --> */}
      <NavLink className="dystolab" to="/dashboard"><b>Dystolab</b></NavLink> section

      </p>
    </div>
    </>
  )
}

export default DystolabInfo