import React, { useState, useEffect } from 'react';
import DystoPunkVXCard from './../DystoPunkVXCard';
import Spinner from './../Spinner';

function VXContainer({dystoPunksVXAvatars, ownedDystoPunksVX}) {
    const [allLoaded, setAllLoaded] = useState(false);
  
    useEffect(() => {
        let allImagesLoaded = true;
        const images = document.querySelectorAll('img');
        images.forEach((img) => {
          if (!img.complete) {
            allImagesLoaded = false;
            img.addEventListener('load', handleImageLoad);
          }
        });
        if (allImagesLoaded) {
          setAllLoaded(true);
        }
        return () => {
          images.forEach((img) => {
            img.removeEventListener('load', handleImageLoad);
          });
        };
      }, []);

      const handleImageLoad = () => {
        const images = document.querySelectorAll('img');
        let allImagesLoaded = true;
        images.forEach((img) => {
          if (!img.complete) {
            allImagesLoaded = false;
          }
        });
        if (allImagesLoaded) {
          setAllLoaded(true);
        }
      };
  return (
    <>
    {!allLoaded && <Spinner />}
    <div className="row justify-content-center">
    {ownedDystoPunksVX.map((id, index) => (
        <DystoPunkVXCard
        key={id.id}
        index={id.id}
        name={id.name}
        tokenId={id.token_id}
        image={id.image_original_url}
        />
    ))}
    </div>
    
    </>
  )
}

export default VXContainer