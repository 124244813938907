import {useState, useEffect } from 'react'

function NoCrates({cratesFloor}) {
    const [floor, setFloor] = useState("")
    useEffect(() => {
        setFloor(cratesFloor.toFixed(2))
    }, [floor, cratesFloor])
    
  return (
    <div className="col-sm-12">
        <p className="text-center">Looks like you don't have a <b className="dystopunks">Crate</b>
        <br />
        </p>
        {floor !== "0.00" && <p>The current floor price is <b className="dekadente">{floor}Ξ</b></p>}
        <a className="btn btn-primary" href="https://opensea.io/collection/dystolabs-vx-crates" target="_blank" rel="noopener noreferrer" type="button">Buy on OpenSea</a>
        <br />
    </div>
  )
}

export default NoCrates
