import {useState, useEffect} from 'react'
import { useContractRead, useAccount } from 'wagmi'
import {credsContract} from './contracts/allContracts.js'
import { useIsMounted } from "./hooks/useIsMounted";

function Creds() {
  const mounted = useIsMounted();
  // creds balance
  const [creds, setCreds] = useState("")
  const { address, isConnected } = useAccount()

  const { data } = useContractRead({
    ...credsContract,
    functionName: 'balanceOf',
    args: [address],
    enabled: isConnected,
    watch: true,
    onSuccess(data) {
      setCreds(Math.trunc(data.toString() / 1000000000000000000))
    },
  })

  useEffect(() => {
    setCreds(Math.trunc(data?.toString() / 1000000000000000000))
  }, [data, creds])
  
  console.log()
  return (
    <>
    {mounted && <>{/* <!-- $CREDS Component --> */}
    <div className="creds-button text-center">
    <div className="d-none d-lg-block">
    <a href="https://www.coingecko.com/en/coins/creds" target="_blank" rel="noopener noreferrer"><img src="img/logos/creds.png" alt="$CREDS" className="creds-btn" /></a>

    {/* <!-- Desktop $CREDS Balance --> */}
    {/* <!-- Show only when connected --> */}
   {isConnected && <p><b className="stats-color"><img src="img/logos/cred.gif" alt="$CREDS" className="img-fluid mint-icons" />{creds}</b></p>}

    </div>

    {/* <!-- Mobile $CREDS Balance --> */}
    {/* <!-- Show only when connected --> */}
    {isConnected && <div className="creds-mobile d-lg-none">
    <p><b className="stats-color-mobile"><img src="img/logos/cred.gif" alt="$CREDS" className="img-fluid mint-icons" />{creds}</b></p>
    </div>}

    </div></>}
    </>
  )
}

export default Creds