import CrateABI from '../../abi/crateABI.json'
import DystolabsABI from '../../abi/dystolabsABI.json'
import DystoPunksABI from '../../abi/dystopunksABI.json'
import DystoPunksVXABI from '../../abi/dystopunksvxABI.json'
import CredsABI from '../../abi/credsABI.json'

// Mainnet - comment out if not using
const dystopunks = '0xbEA8123277142dE42571f1fAc045225a1D347977';
const dystopunksVX = '0xf91523Bc0ffA151ABd971f1b11D2567d4167DB3E';
const creds = '0xc13F4F0F865bAc08F62654B57E38669EbC4747a3';
const crates = '0xC50F11281b0821E5a9AD3DD77C33Eaf82d3094f4';
const vxAssets = '0x2aF55f1Fa2Ac44E2F5a199FF16E5eafAe37B7389';

// // Goerli TESTNET - comment out if not using
// const dystopunks = '0x0F04ED6601D7A10798C2f4eaF8256323ceDD0A67';
// const dystopunksVX = '0xdC70A72380edc2b3F03A32aBE81E221C783ABe30';
// const creds = '0x9A9d0Ac4B14e6a6D9f84756a551907b08F466c1f';
// const crates = '0x78069D22F7086D5339F885ed039b01aF4C4A7C69';
// const vxAssets = '0xba7d5835987a12453A9B3f2D26005df2d956A805';

export const dystoPunksContract = {
  address: dystopunks,
  abi: DystoPunksABI,
}
export const dystoPunksVXContract = {
  address: dystopunksVX,
  abi: DystoPunksVXABI,
}
export const credsContract = {
  address: creds,
  abi: CredsABI,
}
export const crateContract = {
    address: crates,
    abi: CrateABI,
}
export const dystolabContract = {
  address: vxAssets,
  abi: DystolabsABI,
}

