import React from 'react'

function LitepaperInfo() {
  return (
    <>
    {/* <!-- Litepaper Info --> */}
    <div className="col-sm-12">
      <p className="text-center">For more information about the project please read the&nbsp;
      <a href="https://litepaper.dystopunks.net/" target="_blank" rel="noopener noreferrer"><b>Litepaper</b></a>
      </p>
    </div>
    </>
  )
}

export default LitepaperInfo