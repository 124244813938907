import { useState, useEffect } from "react";
import {usePrepareContractWrite,useContractWrite,useWaitForTransaction,} from "wagmi";
import { useDebounce } from "../hooks/useDebounce";
import {ethers} from 'ethers'
import {dystoPunksVXContract} from '../contracts/allContracts.js'

const mintPrice = 0.077;

export function MintVX({setMintStatus}) {
  const [mintAmount, setMintAmount] = useState("");
  const debouncedMintAmount = useDebounce(mintAmount, 500);
  const [readyToMint, setReadyToMint] = useState(false)

  // enforce no 0 and max of 10
  const handleSanity = async (e) => {
    if(e.target.value > 10) {
      setMintAmount(10)
      setReadyToMint(true)
    } else if(e.target.value <= 0){
      setMintAmount(1)
      setReadyToMint(true)
    } else {
      setMintAmount(e.target.value)
      setReadyToMint(true)
    }
  }

  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    ...dystoPunksVXContract,
    functionName: "publicMint",
    overrides: {
        value: ethers.utils.parseEther((mintAmount * mintPrice).toString()),
    },
    args: [parseInt(mintAmount)],
    enabled: readyToMint,
  });
  const { data, error, isError, write } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if(isSuccess){
      setMintStatus(<><p class="text-center error-text mt-3"><b class="dekadente">Success!:</b> View on <a href={`https://etherscan.io/tx/${data?.hash}`} target="_blank" rel="noopener noreferrer">Etherscan</a></p></>)
    }
  }, [isSuccess])

  useEffect(() => {
    if(isError) {
      console.log(error)
      setMintStatus(
      <>
        <p className="text-center error-text mt-3"><b className="error">Error: </b>{error?.message}</p>
      </>
      )
    }
  }, [isError])

  return (<>
    <div className="input-group mb-3">
      <input
        id="mintamount"
        type="number"
        min="1"
        max="10"
        maxLength="2"
        className="form-control"
        aria-label="Recipient's username"
        aria-describedby="button-addon2"
        onChange={(e) => handleSanity(e)}
        placeholder="Max. 10"
        value={mintAmount}
      />
      {!isLoading && <button
        className="btn btn-outline-primary"
        type="button"
        id="button-addon2"
        onClick={() => write?.()}
      >Mint</button>}
        {isLoading && <button class="btn btn-outline-primary btn-loading" type="button">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Minting...
      </button>}
    </div>
    </>
  );
}