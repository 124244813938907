import { Canvas, useLoader } from "@react-three/fiber"
import { Environment } from "@react-three/drei"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';

function Crate() {
    const Model = () => {
        // location of the 3D model
        const gltf = useLoader(GLTFLoader, "/img/GLTFs/crate.gltf"); 
        return (
          <>
            {/* Use scale to control the size of the 3D model */}
            <primitive object={gltf.scene} scale={0.35} position={[0,0,0]}/>
          </>
        );
      };
      
  return (
      <div className="model">
      <Canvas shadows dpr={[1, 2]}>
        <PerspectiveCamera makeDefault position={[0, 0, -10]}/>
          <ambientLight intensity={0.2}/>
          <spotLight intensity={0.2} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow/>
            <Model/>
            <Environment files="img/bank_vault_4k.hdr"/>
          <OrbitControls autoRotate={true} autoRotateSpeed={1.0}/>
        </Canvas>
      </div>
  )
}

export default Crate