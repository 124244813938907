import {useState, useEffect, useRef} from 'react'
import { NavLink, Link} from "react-router-dom";
import { useIsMounted } from "./hooks/useIsMounted";
import ConnectNavbarButton from './buttons/ConnectNavbarButton';
import ConnectNavbarButtonDesktop from './buttons/ConnectNavbarButtonDesktop';

function Navbar() {
  const mounted = useIsMounted();
  const [toggle, setToggle] = useState(true)
  const [navbarFX, setNavbarFX] = useState('')
  const navbarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setToggle(true);
    }
  };
  

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setNavbarFX('navbar-sticky');
      } else {
        setNavbarFX('eo');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  return (
    <>
    {mounted && <>{/* <!-- Navbar Component --> */}
<header className="mb-auto sticky-top" id="top">
  <div>

  {/* <!-- Desktop Navbar --> */}
  <nav className={`navbar navbar-expand-lg navbar-dark d-none d-lg-block ${navbarFX}`} aria-label="Desktop Navbar">
    <div className="container-fluid">

      <NavLink className="navbar-brand" to="/"><img src="/img/logos/dystopunks.png" alt="DystoPunks" className="navlogo img-fluid" /></NavLink>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#desktop-navbar" aria-controls="desktop-navbar" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"><ion-icon name="menu" className="social"></ion-icon></span>
      </button>

      <div className="collapse navbar-collapse justify-content-lg-end" id="desktop-navbar">

        <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-lg-end">

          {/* <!-- Home Component Button --> */}
          <li className="nav-item">
          <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
          </li>

          {/* <!-- Mint Component Button --> */}
          <li className="nav-item">
          <NavLink className="nav-link" to="/mint">Mint</NavLink>
          </li>

          <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#top" data-bs-toggle="dropdown" aria-expanded="false">Buy</a>
          <ul className="dropdown-menu">
          <li><a className="dropdown-item" href="https://blur.io/collection/dystopunks" target="_blank" rel="noopener noreferrer">DystoPunks</a>
          </li>
          <li><a className="dropdown-item" href="https://blur.io/collection/dystopunks-vx" target="_blank" rel="noopener noreferrer">DystoPunks VX</a>
          </li>
          <li><hr className="dropdown-divider" />
          </li>
          <li><a className="dropdown-item" href="https://opensea.io/collection/dystolabs-vx-crates" target="_blank" rel="noopener noreferrer">VX Crates</a>
          </li>
          <li><a className="dropdown-item" href="https://opensea.io/collection/dystolabs-vx-assets" target="_blank" rel="noopener noreferrer">VX Assets</a>
          </li>
          </ul>
          </li>
          <li className="nav-item">
          <a className="nav-link" href="https://litepaper.dystopunks.net/" target="_blank" rel="noopener noreferrer">Litepaper</a>
          </li>
          <li className="nav-item">
          <a className="nav-link social" href="https://twitter.com/DystoPunks" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-twitter"></ion-icon></a>
          </li>
          <li className="nav-item">
          <a className="nav-link social" href="https://discord.gg/Dystolabs" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-discord"></ion-icon></a>
          </li>
        </ul>

        {/* <!-- Dystolab App Buttton --> */}
        <Link className="btn btn-sm btn-secondary nav-link" to="/dashboard" type="button">Dystolab</Link>

        {/* <!-- Metamask Web3 Connect Button -->*/}
        <ConnectNavbarButtonDesktop />

      </div>

    </div>
  </nav>

  {/* <!-- Mobile Navbar --> */}
  <div className="navbr d-lg-none" >
  <nav ref={navbarRef} className="navbar navbar-expand-lg navbar-dark navbar-mobile fixed-top" aria-label="Mobile Navbar">
    <div className="container-fluid">

      <NavLink className="navbar-brand" to="/"><img src="/img/logos/dystopunks.png" alt="DystoPunks" className="navlogo-mobile img-fluid" /></NavLink>
      <button onClick={() => setToggle(!toggle)} className={`navbar-toggler ${toggle ? "collapsed" : ""}`} type="button" data-bs-toggle="collapse" data-bs-target="#mobile-navbar" aria-controls="mobile-navbar" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"><ion-icon name="menu" class="social"></ion-icon></span>
      </button>

      <div className={`collapse navbar-collapse justify-content-lg-end ${!toggle ? "show" : ""}`} id="mobile-navbar">

        <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-lg-end">
          <hr className="hr-black" />
          <div className="btn-group">

          {/* <!-- Dystolab App Buttton --> */}
          <Link onClick={() => setToggle(!toggle)} className="btn btn-sm btn-secondary nav-link" to="/dashboard" type="button">Dystolab</Link>

          {/* <!-- Metamask Web3 Connect Button -->*/}
          <ConnectNavbarButton setToggle={setToggle} toggle={toggle}/>

          </div>
          <hr className="hr-black" />

          {/* <!-- Home Component Button --> */}
          <li className="nav-item">
          <NavLink onClick={() => setToggle(!toggle)} className="nav-link nav-link-mobile" aria-current="page" to="/">Home</NavLink>
          </li>
          {/* <!-- Mint Component Button --> */}
          <li className="nav-item">
          <NavLink onClick={() => setToggle(!toggle)} className="nav-link nav-link-mobile" to="/mint">Mint</NavLink>
          </li>

          <li className="nav-item dropdown">
          <a className="nav-link nav-link-mobile dropdown-toggle" href="#top" data-bs-toggle="dropdown" aria-expanded="false">Buy</a>
          <ul className="dropdown-menu">
          <li><a className="dropdown-item" href="https://blur.io/collection/dystopunks" target="_blank" rel="noopener noreferrer">DystoPunks</a>
          </li>
          <li><a className="dropdown-item" href="https://blur.io/collection/dystopunks-vx" target="_blank" rel="noopener noreferrer">DystoPunks VX</a>
          </li>
          <li><hr className="dropdown-divider" />
          </li>
          <li><a className="dropdown-item" href="https://opensea.io/collection/dystolabs-vx-crates" target="_blank" rel="noopener noreferrer">Crates</a>
          </li>
          <li><a className="dropdown-item" href="https://opensea.io/collection/dystolabs-vx-assets" target="_blank" rel="noopener noreferrer">VX Assets</a>
          </li>
          </ul>
          </li>
          <li className="nav-item">
          <a className="nav-link nav-link-mobile" href="https://litepaper.dystopunks.net/" target="_blank" rel="noopener noreferrer">Litepaper</a>
          </li>
          <hr className="hr-black" />
          <div className="btn-group">
          <li className="nav-item">
          <a className="nav-link nav-link-mobile social" href="https://twitter.com/DystoPunks" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-twitter"></ion-icon>&nbsp;</a>
          </li>
          <li className="nav-item">
          <a className="nav-link nav-link-mobile social" href="https://discord.gg/Dystolabs" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-discord"></ion-icon></a>
          </li>
          </div>
        </ul>
      </div>

    </div>
  </nav>
  </div>

  </div>
</header></>}
    </>
  )
}

export default Navbar
