import {useEffect, useState} from 'react'

function NoDystoPunksVX({dystoPunksVXFloor}) {
    const [floorPrice, setFloorPrice] = useState("")

    useEffect(() => {
      setFloorPrice(dystoPunksVXFloor?.toFixed(2))
    }, [dystoPunksVXFloor])
  return (
    <div className="col-sm-12">
        <br />
        <p className="text-center">Looks like you don't have a <b className="dystopunks">DystoPunk VX</b>
        <br />
        The current floor price is <b className="dekadente">{floorPrice}Ξ</b>
        <br /><br />
        </p>
        <a className="btn btn-primary" href="https://opensea.io/collection/dystopunks-vx" target="_blank" rel="noopener noreferrer" type="button">Buy on OpenSea</a>
        <br /><br />
    </div>
  )
}

export default NoDystoPunksVX