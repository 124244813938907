import './App.css';
import { WagmiConfig, createClient } from 'wagmi'
import { configureChains, mainnet } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import {useState, useEffect} from 'react'
import {Route, Routes} from 'react-router-dom'
import Navbar from './components/Navbar';
import PageFooter from './components/PageFooter';
import Home from './components/Home';
import Mint from './components/Mint';
import Dashboard from './components/Dashboard';
import Creds from './components/Creds';
import Collection from './components/Collection';
import CrateDex from './components/CrateDex';
import PatchNotes from './components/PatchNotes';
import NotFound from './components/NotFound';
// import axios from 'axios';


const { provider, webSocketProvider } = configureChains(
  [mainnet],
  [
    alchemyProvider({ apiKey: 'sPue7jiY0K33EGP34dtPzUT5VZjxW_Xi', priority: 1 }), 
    publicProvider({ priority: 0 })
  ],
)
 
const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
})

// Mainnet - comment out when not in use!!
const openseaVX = 'https://api.opensea.io/api/v2/listings/collection/dystopunks-vx/best?limit=1'
const openseaV2 = 'https://api.opensea.io/api/v2/listings/collection/dystopunks/best?limit=1'
const openseaCrates = 'https://api.opensea.io/api/v2/listings/collection/dystolab-crates/best?limit=1'

// // Georli TESTNET - comment out when not in use!!
// const openseaVX = 'https://api.opensea.io/api/v1/collection/dystopunks-vx'
// const openseaV2 = 'https://api.opensea.io/api/v1/collection/dystopunks'
// const openseaCrates = 'https://api.opensea.io/api/v1/collection/dystolab-crates'

function App() {
  // floor states
  const [dystoPunksFloor, setDystoPunksFloor] = useState([])
  const [dystoPunksVXFloor, setDystoPunksVXFloor] = useState([])
  const [cratesFloor, setCratesFloor] = useState([])

  // owner states
  const [dystoPunksOwners, setDystoPunksOwners] = useState([])
  const [dystoPunksVXOwners, setDystoPunksVXOwners] = useState([])

  const dystoPunksVXData = async () => {
    const options = {
      method: 'GET',
      headers: {accept: 'application/json', 'X-API-KEY': 'a0e510a4bb174e9cb7ac7d0f8cb1db66'}
    };
    fetch(openseaVX, options)
      .then(response => response.json())
      .then(function (response) {
        console.log("Dystopunks VX Opensea Response: ", response)
        setDystoPunksVXFloor(response?.listings[0]?.price?.current?.value ? response?.listings[0]?.price?.current?.value:0)
        // setDystoPunksVXOwners(response?.collection?.stats?.num_owners)
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  

  const dystoPunksData = async () => {
    const options = {
      method: 'GET',
      headers: {accept: 'application/json', 'X-API-KEY': 'a0e510a4bb174e9cb7ac7d0f8cb1db66'}
    };
    fetch(openseaV2, options)
      .then(response => response.json())
      .then(function (response) {
        console.log("Dystopunks Opensea Response: ", response)
        setDystoPunksFloor(response?.listings[0]?.price?.current?.value ? response?.listings[0]?.price?.current?.value:0)
        // setDystoPunksOwners(response?.collection?.stats?.num_owners)
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  const cratesData = async () => {
    const options = {
      method: 'GET',
      headers: {accept: 'application/json', 'X-API-KEY': 'a0e510a4bb174e9cb7ac7d0f8cb1db66'}
    };
    fetch(openseaCrates, options)
      .then(response => response.json())
      .then(function (response) {
        console.log("Crates Opensea Response: ", response)
        setCratesFloor(response?.listings?.price?.current?.value ? response.listings.price.current.value:0)
      })
      .catch(function (error) {
        console.error(error);
      });
  }


  useEffect(() => {
    dystoPunksData()
  }, [dystoPunksFloor, dystoPunksOwners])

  useEffect(() => {
    dystoPunksVXData()
  }, [dystoPunksVXFloor, dystoPunksVXOwners])

  useEffect(() => {
    cratesData()
  }, [cratesFloor])

  // console.log(cratesFloor)

  return (
    <>
    <WagmiConfig client={client}>
      <Navbar />
      <Creds />
      <Routes> 
          <Route exact path="/" element={<Home /> } /> 
          <Route exact path="/dashboard" element={<Dashboard dystoPunksFloor={dystoPunksFloor} dystoPunksVXFloor={dystoPunksVXFloor} dystoPunksOwners={dystoPunksOwners} dystoPunksVXOwners={dystoPunksVXOwners} /> } /> 
          <Route exact path="/collection" element={<Collection dystoPunksFloor={dystoPunksFloor} dystoPunksVXFloor={dystoPunksVXFloor} dystoPunksOwners={dystoPunksOwners} dystoPunksVXOwners={dystoPunksVXOwners} /> } />
          <Route exact path="/mint" element={<Mint cratesFloor={cratesFloor} />} /> 
          <Route exact path="/cratedex" element={<CrateDex />} />  
          <Route exact path="/patch-notes" element={<PatchNotes />} />
          <Route path="*" element={<NotFound />} />
      </Routes>
      <PageFooter />
    </WagmiConfig>
    </>
  );
}

export default App;
