import { React, useEffect } from "react"
import { NavLink} from "react-router-dom";

function Home() {
  useEffect(() => {
    document.body.classList.add(
      "d-flex",
      "h-100",
      "text-bg-dark",
      "home-bg"
    );
    document
      .getElementById("root")
      .classList.add(
        "cover-container",
        "d-flex",
        "w-100",
        "h-100",
        "p-3",
        "mx-auto",
        "flex-column"
      );

    return function cleanup() {
      document.body.classList.remove(
        "d-flex",
        "h-100",
        "text-bg-dark",
        "background"
      );
      document
        .getElementById("root")
        .classList.remove(
          "cover-container",
          "d-flex",
          "w-100",
          "h-100",
          "p-3",
          "mx-auto",
          "flex-column"
        );
    };
  }, []);
  return (
    <>
    {/* <!-- Home Component --> */}
  <main className="main-info px-3">

  {/* <!-- Desktop Info --> */}
  <div className="container bgscreen d-none d-md-block">
    <div className="row">
    <div className="col-sm-6">
    <img src="img/logos/dystopunks.png" alt="DystoPunks" className="img-fluid" />
    <p className="lead">Welcome to a tech-filled dystopian future. DystoPunks is a utility-focused cyberpunk-themed NFT project that allows holders to generate a utility-governance token called $CREDS and use it in the Dystolab.</p>
    <a href="https://litepaper.dystopunks.net/" className="btn btn-lg btn-outline-primary" target="_blank" rel="noopener noreferrer">Litepaper</a>
    <NavLink to="/mint" className="btn btn-lg btn-outline-success ml-2">Mint VX</NavLink>
    </div>
    </div>
  </div>

  {/* <!-- Mobile Info --> */}
  <div className="container d-md-none">
    <div className="row">
    <div className="col-sm-12">
    <img src="img/logos/dystopunks.png" alt="DystoPunks" className="img-fluid" />
    <p className="lead">Welcome to a tech-filled dystopian future. DystoPunks is a utility-focused cyberpunk-themed NFT project that allows holders to generate a utility-governance token called $CREDS and use it in the Dystolab.</p>
    <a href="https://litepaper.dystopunks.net/" className="btn btn-lg btn-outline-primary" target="_blank" rel="noopener noreferrer">Litepaper</a>
    <NavLink to="/mint" className="btn btn-lg btn-outline-success ml-2">Mint VX</NavLink>
    </div>
    </div>
  </div>

</main>
    </>
  )
}

export default Home