import {useState, useEffect} from 'react'
import {useAccount, usePrepareContractWrite, useContractWrite, useWaitForTransaction, useContractRead} from 'wagmi'
import {credsContract} from '../contracts/allContracts.js'
import { useIsMounted } from "../hooks/useIsMounted";

function ClaimCreds() {
  const mounted = useIsMounted();
  const [credsClaimable, setCredsClaimable] = useState(0)
  const { isConnected, address } = useAccount()

  const { data: credsAvailable } = useContractRead({
    ...credsContract,
    functionName: 'totalAvailable',
    args: [address],
    enabled: isConnected,
    onSuccess(credsAvailable) {
      setCredsClaimable(credsAvailable.toString())
    },
  })

  const { config, error: prepareError, isError: isPrepareError, } = usePrepareContractWrite({
    ...credsContract,
    functionName: "claim",
    enabled: parseInt(credsClaimable) > 0,
    overrides: {
        value: "0",
    },
  });
  const { data, error, isError, write } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    setCredsClaimable(credsAvailable?.toString())
  }, [credsAvailable])
  

  return (<>
    {mounted &&
    <>
      <div className="col-sm-6">
        {/* <!-- Claim $CREDS Button --> */}
        {!isLoading && !isSuccess && 
        <button onClick={() => write?.()} className="btn btn-primary" type="button">
          Claim $CREDS
        </button>}
        {isLoading && !isSuccess &&
        <button class="btn btn-primary btn-loading" type="button">
          <span class="spinner-border spinner-border-sm neo-black" role="status" aria-hidden="true"></span>
            Claiming..
          </button>}
        {isSuccess && !isLoading && <button class="btn btn-primary" type="button">Claimed!</button>}
      </div>
      {(isPrepareError || isError) && (<>
        {(prepareError || error)?.code === -32603 && <p className="text-center error-text mt-3"><b className="error">Error:</b> Insufficient funds</p>}
        {(prepareError || error)?.code === -32000 && <p className="text-center error-text mt-3"><b className="error">Error:</b> Insufficient funds</p>}
        {(prepareError || error)?.code === 4001 && <p className="text-center error-text mt-3"><b className="error">Error:</b> User rejected</p>}
      </>)}
      </>
    }</>
  )
}

export default ClaimCreds