import {useState, useEffect} from 'react'
import {usePrepareContractWrite,useContractWrite,useWaitForTransaction,} from "wagmi";
import {dystoPunksVXContract} from '../contracts/allContracts.js'

function ClaimVX({toClaim}) {
  const [claims, setClaims] = useState([])

  useEffect(() => {
    setClaims(toClaim)
  }, [toClaim])
  

    const { config, error: prepareError, isError: isPrepareError, } = usePrepareContractWrite({
        ...dystoPunksVXContract,
        functionName: "DystosClaim",
        args: [claims],
        enabled: Boolean(claims),
      });
      const { data, error, isError, write } = useContractWrite(config);
    
      const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
      });
      console.log(config)

  return (
    <>
      {/* <!-- Desktop Claim VX Button --> */}
      <div className="col-md-6 text-right d-none d-md-block d-xl-block d-xxl-block">
        <div className="col-sm-4">
          {!isLoading && !isSuccess && <button onClick={() => write?.()} className="btn btn-success" type="button">Claim Selected</button>}
          {isLoading && !isSuccess &&
          <button className="btn btn-success btn-loading" type="button">
            <span className="spinner-border spinner-border-sm neo-black" role="status" aria-hidden="true"></span>
              Claiming...
          </button>}
          {isSuccess && !isLoading && <button className="btn btn-success" href="" type="button">Claimed!</button>}
          {(isPrepareError || isError) && (<>
          {(prepareError || error)?.code === -32603 && <p className="text-center error-text mt-3"><b className="error">Error:</b> Insufficient funds</p>}
          {(prepareError || error)?.code === -32000 && <p className="text-center error-text mt-3"><b className="error">Error:</b> Insufficient funds</p>}
          {(prepareError || error)?.code === 4001 && <p className="text-center error-text mt-3"><b className="error">Error:</b> User rejected</p>}
          </>)}
        </div>
      </div>

      {/* <!-- Mobile Claim VX Button --> */}
      <div className="col-md-6 text-left d-block d-md-none d-xl-none d-xxl-none">
      <div className="col-sm-4">
        {!isLoading && !isSuccess && <button onClick={() => write?.()} className="btn btn-success" type="button">Claim Selected</button>}
        {isLoading && 
        <button className="btn btn-success btn-loading" type="button">
          <span className="spinner-border spinner-border-sm neo-black" role="status" aria-hidden="true"></span>
            Claiming...
        </button>}
        {isSuccess && <button className="btn btn-success" href="" type="button">Claimed!</button>}
        {(isPrepareError || isError) && (<>
        {(prepareError || error)?.code === -32603 && <p className="text-center error-text mt-3"><b className="error">Error:</b> Insufficient funds</p>}
        {(prepareError || error)?.code === -32000 && <p className="text-center error-text mt-3"><b className="error">Error:</b> Insufficient funds</p>}
        {(prepareError || error)?.code === 4001 && <p className="text-center error-text mt-3"><b className="error">Error:</b> User rejected</p>}
      </>)}
      </div>
      </div>
    </>
  )
}

export default ClaimVX