import { useEffect, useState } from 'react';

export function useUniqueSummer(ownerVXAssets) {
    // Creating variable to store the sum
    const [totalUniqueAssets, setTotalUniqueAssets] = useState("")
    useEffect(() => {
        let sum = 0;
        for (let i = 0; i < ownerVXAssets?.length; i++) {
            if(ownerVXAssets[i].toNumber() > 0){
                sum ++;
            }  
        }
        setTotalUniqueAssets(sum)
    }, [ownerVXAssets])
    
    return totalUniqueAssets;
  }