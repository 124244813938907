import { useAccount, useConnect, useEnsName  } from 'wagmi'
import { useDisconnect } from 'wagmi'
import truncateEthAddress from 'truncate-eth-address'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { useIsMounted } from "../hooks/useIsMounted";
import { Link } from 'react-router-dom';

function ConnectNavbarButtonDesktop() {

    const mounted = useIsMounted();
    const { address, isConnected } = useAccount()
    const { data: ENS } = useEnsName({
        address: address,
    })
    const { connect } = useConnect({
        connector: new InjectedConnector({options: {
          name: 'Injected',
          shimDisconnect: true,
        },}),
    })
    const { disconnect } = useDisconnect()

  return (<>
    {mounted && isConnected ? 
        <>
        <div className="dropdown">
          <a className="btn btn-sm btn-success nav-link dropdown-toggle" href="#top" data-bs-toggle="dropdown" aria-expanded="false">{isConnected && (ENS || truncateEthAddress(address))}</a>
          <ul className="dropdown-menu">
          <li><Link className="dropdown-item" to="/collection">My Collection</Link>
          </li>
          <li><Link className="dropdown-item" to="/cratedex">CrateDex</Link>
          </li>
          <li><hr className="dropdown-divider" />
          </li>
          <li><Link onClick={() => disconnect()} className="dropdown-item dropdown-disconnect" to="/">Disconnect</Link>
          </li>
          </ul>
        </div>
        </>
        :
        <button onClick={() => connect()} className="btn btn-sm btn-success nav-link" type="button">Connect</button>}
        </>
  )
}

export default ConnectNavbarButtonDesktop