import React from 'react'

function Spinner() {
  return (
    <div className="spinner-border m-5" role="status">
        <span className="visually-hidden">Loading...</span>
    </div>
  )
}

export default Spinner