import { useConnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { useIsMounted } from "../hooks/useIsMounted";

function ConnectButton() {
    const mounted = useIsMounted();
    const { connect } = useConnect({
        connector: new InjectedConnector({options: {
          name: 'Injected',
          shimDisconnect: true,
        },}),
    })

  return (<>
    {mounted &&
    <>
        {/* <!-- Connect Metamask --> */}
        <div className="col-sm-12">
        <p className="text-center">To access the <b>Web3 DApp</b> please connect your&nbsp;
        <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer"><b>Metamask</b></a> wallet
        <br /><br />
        </p>
        {/* <!-- Metamask Web3 Connect Button -->
        <!-- Show ENS once connected --> */}
        <button onClick={() => connect()} className="btn btn-success" type="button">Connect Metamask</button>
        </div>
    </>
    }
    </>
  )
}

export default ConnectButton