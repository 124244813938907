import {React, useEffect} from 'react'
import { useIsMounted } from "./hooks/useIsMounted";
import ConnectButton from './buttons/ConnectButton';

function Connect() {
  const mounted = useIsMounted();
  return (
    <>
    {mounted && <>
    <main className="main-info text-center px-3">
      <div className="container">
        <div className="row">
          <ConnectButton />
        </div>
      </div>
    </main></>}
    </>
  )
}

export default Connect