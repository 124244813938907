import {React, useEffect, useState} from 'react'
import {dystoPunksContract, dystoPunksVXContract} from './contracts/allContracts.js'
import LitepaperInfo from './info/LitepaperInfo';
import DystolabMenu from './menus/DystolabMenu';
import { useContractRead, useAccount } from 'wagmi'
import { useIsMounted } from "./hooks/useIsMounted";
import axios from "axios";
import ClaimVX from './mints/ClaimVX';
import Mintinfo from './info/Mintinfo';
import Spinner from './Spinner';
import NoDystoPunks from './menus/NoDystoPunks';
import NoDystoPunksVX from './menus/NoDystoPunksVX';
import VXContainer from './containers/VXContainer';
import DystoPunkContainer from './containers/DystoPunkContainer';
import Connect from './Connect';

function Collection({ 
  dystoPunksFloor,
  dystoPunksVXFloor,
  dystoPunksOwners,
  dystoPunksVXOwners}) {

  const mounted = useIsMounted();
  const { address, isConnected } = useAccount()
  const [toggle, setToggle] = useState(false)
  const [ownedDystoPunks, setOwnedDystoPunks] = useState([])
  const [dystoPunksVXAvatars, setDystoPunksVXAvatars] = useState("")
  const [ownedDystoPunksVX, setOwnedDystoPunksVX] = useState([])
  const [toClaim, setToClaim] = useState([])
  const [balanceOfDystos, setBalanceOfDystos] = useState("")
  //console.log(Boolean(balanceOfDystos))
  //console.log("is loading? " + loading)



  const handleToggle = () => {
    setBalanceOfDystos("")
    setDystoPunksVXAvatars("")
    setToggle(!toggle)
  }
  
  useEffect(() => {
    document.body.classList.add(
      "d-flex",
      "text-bg-dark",
      "dystolab-bg"
    );
    document
      .getElementById("root")
      .classList.add(
        "cover-container",
        "d-flex",
        "w-100",
        "h-100",
        "p-3",
        "mx-auto",
        "flex-column"
      );

    return function cleanup() {
      document.body.classList.remove(
        "d-flex",
        "text-bg-dark",
        "dystolab-bg"
      );
      document
        .getElementById("root")
        .classList.remove(
          "cover-container",
          "d-flex",
          "w-100",
          "h-100",
          "p-3",
          "mx-auto",
          "flex-column"
        );
    };
  }, []);



  useEffect(() => {
          // pulling vx avatar holdings of user from opensea
    const openseaVXData = async () => {
      const options = {
          method: 'GET',
          url: `https://api.opensea.io/api/v2/chain/ethereum/account/${address}/nfts`,
          params: {
            collection_slug: 'dystopunks-vx',
            limit: '200',
          },
          headers: {accept: 'application/json', 'X-API-KEY': 'a0e510a4bb174e9cb7ac7d0f8cb1db66'}
        };
        
        axios
          .request(options)
          .then(function (response) {
            const tokens = response.data.assets
            tokens.sort(function (a, b) {
              return a.token_id - b.token_id;
            });
            //console.log(tokens)
            setOwnedDystoPunksVX(tokens);
          })
          .catch(function (error) {
            console.error(error);
          });
    }
    openseaVXData(address)
  }, [address])
  
  
  // eslint-disable-next-line
  const { data: dystos } = useContractRead({
    ...dystoPunksContract,
    functionName: 'tokensOfOwner',
    args: [address],
    enabled: isConnected,
    onSuccess(dystos) {
      setOwnedDystoPunks(dystos)
      // console.log(dystos)
    },
  })
  // eslint-disable-next-line
  const { data: dystosBalance } = useContractRead({
    ...dystoPunksContract,
    functionName: 'balanceOf',
    args: [address],
    enabled: isConnected && !toggle,
    onSuccess(dystosBalance) {
      setBalanceOfDystos(dystosBalance)
    },
  })
  // eslint-disable-next-line
  const { data: dystosVX } = useContractRead({
    ...dystoPunksVXContract,
    functionName: 'balanceOf',
    args: [address],
    enabled: isConnected && toggle,
    onSuccess(dystosVX) {
      setDystoPunksVXAvatars(dystosVX)
    },
  })

  const handleChecked = (e) => {
    if(e.target.checked) {
      setToClaim([...toClaim, e.target.value])
      // console.log(toClaim)
    } else {
      setToClaim(toClaim.filter((toClaim) => toClaim !== e.target.value))
      // console.log(toClaim)
    }
  }
  
  
  // useEffect(() => { setLoading(loading => false)}, [])
  // console.log(dystoPunksVXAvatars)
  
  return (
    <>
      {mounted && isConnected ? 
      <>
      <main className="main-info text-center px-3">
        <div className="container">
          <div className="row">

            {/* <!-- Dystolab Menu --> */}
            <DystolabMenu />

            <div className="col-sm-12 mint-bg">
              <div className="row">

                {/* <!-- Collections Menu --> */}
                <div className="col-sm-6 text-left">
                  <div className="btn-group">
                  <input type="radio" className="btn-check" name="options-outlined" id="dystopunks" autoComplete="off" checked={!toggle} onChange={handleToggle} />
                  <label className="btn btn-outline-primary" htmlFor="dystopunks">DystoPunks</label>
                  <input type="radio" className="btn-check d-none d-md-block d-xl-block d-xxl-block" name="options-outlined" id="dystopunks-vx" autoComplete="off"  onChange={handleToggle} />
                  <label className="btn btn-outline-primary d-none d-md-block d-xl-block d-xxl-block" htmlFor="dystopunks-vx">DystoPunks VX</label>
                  <input type="radio" className="btn-check d-block d-md-none d-xl-none d-xxl-non" name="options-outlined" id="vx" autoComplete="off"  onChange={handleToggle} />
                  <label className="btn btn-outline-primary d-block d-md-none d-xl-none d-xxl-none" htmlFor="vx">VX</label>
                  </div>
                </div>

                {/* Claim VX */}
               {toClaim.length > 0 ? <ClaimVX toClaim={toClaim} /> : null}

              </div>
              <hr />

              {/* <!-- NFTs --> */}
              <div className="row justify-content-center">
                
                {!toggle && <>{balanceOfDystos > 0 ? 
                <>
                {/* <!-- DystoPunks --> */}
                <DystoPunkContainer ownedDystoPunks={ownedDystoPunks} handleChecked={handleChecked} />
                </> 
                : 
                <>
                {/* <!-- No DystoPunks --> */}
                {/* <!-- Show only if the connected wallet doesn't have DystoPunks --> */}
                  {parseInt(balanceOfDystos) === 0 ? <NoDystoPunks dystoPunksFloor={dystoPunksFloor} />:<Spinner />}
                </>
                }</>}
                {toggle && <>{parseInt(dystoPunksVXAvatars) > 0 ? <VXContainer dystoPunksVXAvatars={dystoPunksVXAvatars} ownedDystoPunksVX={ownedDystoPunksVX}/>
                : 
                <>
                {/* <!-- No DystoPunks VX --> */}
                {/* <!-- Show only if the connected wallet doesn't have DystoPunks VX --> */}
                {parseInt(dystoPunksVXAvatars) === 0 ? <NoDystoPunksVX dystoPunksVXFloor={dystoPunksVXFloor} />:<Spinner />}
                </>}</>}
            </div>
          </div>

          {/* <!-- Mint Info --> */}
          <Mintinfo />
          {/* <!-- Litepaper Info --> */}
          <LitepaperInfo />

          </div>
        </div>
      </main>
      </>:<Connect/>}
    </>
  )
}

export default Collection