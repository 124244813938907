import {useState, useEffect} from 'react'
import { useContractRead, useAccount } from 'wagmi'
import {dystoPunksVXContract} from './contracts/allContracts.js'
import { useIsMounted } from "./hooks/useIsMounted";

function DystoPunkCard({token, handleChecked }) {
    const mounted = useIsMounted();
    const { address, isConnected, isConnecting, isDisconnected } = useAccount()
    const [vxClaimed, setVXClaimed] = useState(true)
    const nftID = token - 1
    const [loaded, setLoaded] = useState(false);

    const handleImageLoad = () => {
      setLoaded(true);
    };

    const { data: isVXClaimed, isLoading: isVXClaimedLoading } = useContractRead({
        ...dystoPunksVXContract,
        functionName: 'claimedDysto',
        args: [parseInt(token - 1)],
        enabled: isConnected,
        watch: !vxClaimed,
        onSuccess(isVXClaimed) {
          setVXClaimed(isVXClaimed)
        },
      })
    
  return (
    <>
        {mounted && <>{/* <!-- DystoPunks -->
        <!-- Show all DystoPunks NFTs and link to OpenSea --> */}
      <div className="col-md-2">
        <a href={`https://opensea.io/assets/ethereum/0xbea8123277142de42571f1fac045225a1d347977/${(nftID).toString()}`} target="_blank" rel="noopener noreferrer">
          <img src={`img/dystopunks/${token <= 100 ? `${token.toString()}.gif` : `${token.toString()}.png`}`} alt="DystoPunk" onLoad={handleImageLoad} className="img-fluid collection" />
        </a>

        {/* <!-- Desktop & Mobile VX Checkbox --> */}
        {!vxClaimed && <div className="form-check d-md-none d-lg-block">
        <input className="form-check-input" type="checkbox" onChange={handleChecked} value={nftID} id="claim-vx"  />
        <label className="form-check-label" htmlFor="claim-vx">Claim VX</label>
        </div>}

        {/* <!-- Tablet VX Checkbox --> */}
        {!vxClaimed && <div className="form-check d-none d-md-block d-lg-none">
        <input  className="form-check-input" type="checkbox" onChange={handleChecked} value={nftID} id="claim-vx"  />
        <label className="form-check-label" htmlFor="claim-vx">VX</label>
        </div>}

      </div></>}
    </>
  )
}

export default DystoPunkCard