import React from 'react'

function VXDex({ownerVXAssets}) {

  return (
    <>
    <div className="col-sm-12 mint-bg">

{/* <!-- NFTs --> */}
<div className="row justify-content-center">

{/* <!-- VX Assets --> */}
{/* <!-- Insert "active-lab" className to the "img" element if the connected wallet owns the NFT --> */}

{/* <!-- #1 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/1" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/1.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[0] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #2 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/2" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/2.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[1] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #3 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/3" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/3.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[2] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #4 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/4" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/4.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[3] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #5 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/5" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/5.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[4] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #6 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/6" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/6.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[5] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #7 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/7" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/7.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[6] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #8 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/8" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/8.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[7] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #9 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/9" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/9.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[8] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #10 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/10" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/10.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[9] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #11 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/11" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/11.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[10] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #12 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/12" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/12.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[11] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #13 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/13" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/13.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[12] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #14 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/14" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/14.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[13] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #15 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/15" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/15.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[14] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #16 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/16" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/16.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[15] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #17 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/17" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/17.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[16] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #18 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/18" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/18.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[17] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #19 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/19" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/19.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[18] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #20 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/20" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/20.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[19] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #21 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/21" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/21.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[20] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #22 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/22" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/22.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[21] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #23 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/23" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/23.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[22] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #24 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/24" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/24.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[23] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #25 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/25" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/25.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[24] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #26 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/26" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/26.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[25] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #27 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/27" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/27.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[26] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #28 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/28" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/28.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[27] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #29 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/29" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/29.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[28] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #30 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/30" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/30.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[29] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #31 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/31" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/31.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[30] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #32 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/32" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/32.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[31] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #33 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/33" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/33.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[32] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #34 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/34" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/34.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[33] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #35 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/35" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/35.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[34] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #36 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/36" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/36.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[35] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #37 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/37" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/37.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[36] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #38 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/38" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/38.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[37] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #39 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/39" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/39.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[38] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #40 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/40" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/40.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[39] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #41 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/41" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/41.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[40] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #42 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/42" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/42.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[41] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #43 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/43" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/43.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[42] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #44 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/44" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/44.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[43] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #45 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/45" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/45.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[44] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #46 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/46" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/46.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[45] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #47 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/47" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/47.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[46] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #48 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/48" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/48.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[47] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #49 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/49" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/49.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[48] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #50 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/50" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/50.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[49] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #51 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/51" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/51.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[50] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #52 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/522" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/52.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[51] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #53 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/53" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/53.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[52] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #54 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/54" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/54.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[53] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #55 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/55" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/55.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[54] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #56 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/56" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/56.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[55] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #57 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/57" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/57.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[56] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #58 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/58" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/58.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[57] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #59 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/59" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/59.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[58] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #60 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/60" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/60.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[59] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #61 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/61" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/61.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[60] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #62 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/62" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/62.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[61] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #63 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/63" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/63.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[62] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #64 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/64" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/64.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[63] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #65 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/65" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/65.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[64] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #66 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/66" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/66.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[65] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #67 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/67" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/67.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[66] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #68 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/68" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/68.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[67] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #69 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/69" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/69.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[68] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #70 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/70" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/70.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[69] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #71 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/71" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/71.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[70] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #72 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/72" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/72.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[71] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #73 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/73" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/73.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[72] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #74 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/74" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/74.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[73] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #75 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/75" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/75.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[74] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #76 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/76" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/76.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[75] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #77 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/77" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/77.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[76] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #78 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/78" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/78.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[77] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #79 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/79" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/79.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[78] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #80 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/80" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/80.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[79] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #81 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/81" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/81.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[80] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #82 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/82" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/82.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[81] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #83 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/83" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/83.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[82] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #84 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/84" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/84.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[83] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #85 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/85" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/85.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[84] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #86 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/86" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/86.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[85] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #87 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/87" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/87.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[86] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #88 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/88" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/88.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[87] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #89 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/89" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/89.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[88] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #90 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/90" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/90.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[89] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #91 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/91" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/91.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[90] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #92 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/92" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/92.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[91] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #93 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/93" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/93.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[92] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #94 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/94" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/94.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[93] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #95 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/95" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/95.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[94] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #96 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/96" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/96.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[95] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #97 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/97" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/97.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[96] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #98 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/98" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/98.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[97] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #99 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/99" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/99.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[98] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #100 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/100" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/100.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[99] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #101 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/101" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/101.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[100] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #102 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/102" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/102.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[101] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #103 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/103" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/103.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[102] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #104 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/104" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/104.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[103] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #105 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/105" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/105.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[104] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #106 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/106" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/106.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[105] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #107 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/107" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/107.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[106] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #108 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/108" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/108.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[107] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #109 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/109" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/109.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[108] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #110 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/110" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/110.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[109] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #111 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/111" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/111.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[110] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #112 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/112" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/112.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[111] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #113 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/113" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/113.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[112] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #114 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/114" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/114.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[113] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #115 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/115" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/115.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[114] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #116 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/116" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/116.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[115] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #117 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/117" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/117.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[116] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #118 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/118" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/118.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[117] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #119 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/119" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/119.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[118] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #120 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/120" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/120.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[119] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #121 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/121" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/121.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[120] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #122 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/122" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/122.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[121] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #123 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/123" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/123.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[122] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #124 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/124" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/124.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[123] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #125 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/125" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/125.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[124] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #126 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/126" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/126.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[125] > 0 ? "active-lab":""}`} /></a>
</div>

{/* <!-- #127 --> */}
<div className="col-md-2">
  <a href="https://opensea.io/assets/ethereum/0x2af55f1fa2ac44e2f5a199ff16e5eafae37b7389/127" target="_blank" rel="noopener noreferrer"><img src="img/cratedex/127.jpg" alt="VX Asset" className={`img-fluid collection-lab ${ownerVXAssets[126] > 0 ? "active-lab":""}`} /></a>
</div>

</div>
</div>
    </>
  )
}

export default VXDex