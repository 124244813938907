import { useState, useEffect } from 'react';
import {usePrepareContractWrite,useContractWrite,useWaitForTransaction,} from "wagmi";
import {dystolabContract} from '../contracts/allContracts.js'

function BurnOne({totalUserCrates, setCrateStatus}) {
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    if(parseInt(totalUserCrates) > 0) {
      setIsEnabled(true)
    } else {
      setIsEnabled(false)
    }
  }, [totalUserCrates])

  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    ...dystolabContract,
    functionName: "mint",
    args: [1],
    enabled: isEnabled,
    
  });
  const { data, error, isError, write } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if(isSuccess) {
      setCrateStatus(
      <>
        <p class="text-center error-text mt-3"><b class="dekadente">Success!:</b> View on <a href={`https://etherscan.io/tx/${data?.hash}`} target="_blank" rel="noopener noreferrer">Etherscan</a></p>
      </>
      )
    }
  }, [isSuccess])

  useEffect(() => {
    if(isError) {
      console.log(error)
      setCrateStatus(
      <>
        <p className="text-center error-text mt-3"><b className="error">Error: </b>{error?.message}</p>
      </>
      )
    }
  }, [isError])

  return (<>
    {!isLoading &&
    <button 
      className="btn btn-primary"
      onClick={() => write?.()} 
      disabled={!isEnabled} 
      type="button" 
      id="burn-crates-x1">Burn x1</button>}
    {isLoading && !isSuccess && <button className="btn btn-primary btn-loading" type="button">
      <span className="spinner-border spinner-border-sm neo-black" role="status" aria-hidden="true"></span>
      Burning...
      </button>}
  </>
  )
}

export default BurnOne