import { useEffect, useState } from 'react'
import {dystoPunksContract, dystoPunksVXContract, credsContract, dystolabContract, crateContract} from './contracts/allContracts.js'
import { useContractRead, useAccount } from 'wagmi'
import {useAddressArray} from './hooks/useAddressArray'
import { NavLink} from "react-router-dom";
import Connect from './Connect';
import LitepaperInfo from './info/LitepaperInfo';
import { useIsMounted } from "./hooks/useIsMounted";
import ClaimCreds from './writes/ClaimCreds';
import Mintinfo from './info/Mintinfo';
import { ethers } from 'ethers';


function Dashboard({
  hasDysto,
  hasDystoVX,
  assets,
  dystoPunksFloor,
  dystoPunksVXFloor,
  dystoPunksOwners,
  dystoPunksVXOwners}) {
    const mounted = useIsMounted();
    const { address, isConnected } = useAccount()
    const [dystoPunks, setDystoPunks] = useState('0')
    const [dystoPunksVX, setDystoPunksVX] = useState('0')
    const [crates, setCrates] = useState('0')
    const [credsClaimable, setCredsClaimable] = useState('0')
    const [dystoPunksCreds, setDystoPunksCreds] = useState('0')
    const [dystoPunksCheck, setDystoPunksCheck] = useState('')
    const [punkToCheck, setPunkToCheck] = useState('')
    const [vxClaimed, setVXClaimed] = useState(null)
    const addressCheck = useAddressArray(address)
    const allAssetsVX = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127]
    const [ownerVXAssets, setOwnerVXAssets] = useState([])
    const [totalOwnedVXAssets, setTotalOwnedVXAssets] = useState('0')
    const [v2Floor, setV2Floor] = useState('0')
    const [vxFloor, setvxFloor] = useState('0')
    // console.log(ownerVXAssets)


    useEffect(() => {
      if(dystoPunksFloor > 0) {
        setV2Floor(ethers.utils.formatEther(dystoPunksFloor))
      }
    }, [dystoPunksFloor])

    useEffect(() => {
      if(dystoPunksVXFloor > 0) {
        setvxFloor(dystoPunksVXFloor?.toFixed(2))
      }
    }, [dystoPunksFloor, dystoPunksVXFloor])
    



  useEffect(() => {
    document.body.classList.add(
      "d-flex",
      "text-bg-dark",
      "dystolab-bg"
    );
    document
      .getElementById("root")
      .classList.add(
        "cover-container",
        "d-flex",
        "w-100",
        "h-100",
        "p-3",
        "mx-auto",
        "flex-column"
      );

    return function cleanup() {
      document.body.classList.remove(
        "d-flex",
        "text-bg-dark",
        "dystolab-bg"
      );
      document
        .getElementById("root")
        .classList.remove(
          "cover-container",
          "d-flex",
          "w-100",
          "h-100",
          "p-3",
          "mx-auto",
          "flex-column"
        );
    };
  }, []);
  // eslint-disable-next-line
  const { data: dystos } = useContractRead({
    ...dystoPunksContract,
    functionName: 'balanceOf',
    args: [address],
    enabled: isConnected,
    onSuccess(dystos) {
      setDystoPunks(dystos?.toString())
    },
  })
  // eslint-disable-next-line
  const { data: dystoVX } = useContractRead({
    ...dystoPunksVXContract,
    functionName: 'balanceOf',
    args: [address],
    enabled: isConnected,
    onSuccess(dystoVX) {
      setDystoPunksVX(dystoVX?.toString())
    },
  })
  // eslint-disable-next-line
  const { data: crateBal } = useContractRead({
    ...crateContract,
    functionName: 'balanceOf',
    args: [address, '77'],
    enabled: isConnected,
    onSuccess(crateBal) {
      setCrates(crateBal?.toString())
    },
  })
  // eslint-disable-next-line
  const { data: claimableCreds } = useContractRead({
    ...credsContract,
    functionName: 'totalAvailable',
    args: [address],
    watch: isConnected,
    enabled: isConnected,
    onSuccess(claimableCreds) {
      setCredsClaimable(Math.trunc(claimableCreds?.toString() / 1000000000000000000))
    },
  })
  // eslint-disable-next-line
  const { data: punksCreds } = useContractRead({
    ...credsContract,
    functionName: 'punkCreds',
    args: [parseInt(punkToCheck - 1)],
    enabled: isConnected && Boolean(punkToCheck),
    onSuccess(punksCreds) {
      setDystoPunksCreds(Math.trunc(punksCreds?.toString() / 1000000000000000000))
    },
  })
  // eslint-disable-next-line
  const { data: isVXClaimed, isLoading: isVXClaimedLoading } = useContractRead({
    ...dystoPunksVXContract,
    functionName: 'claimedDysto',
    args: [parseInt(punkToCheck - 1)],
    enabled: isConnected && Boolean(punkToCheck),
    onSuccess(isVXClaimed) {
      setVXClaimed(isVXClaimed)
    },
  })
  // eslint-disable-next-line
  const { data: dataVXAssets } = useContractRead({
    ...dystolabContract,
    functionName: 'balanceOfBatch',
    args: [addressCheck, allAssetsVX],
    enabled: isConnected && Boolean(addressCheck) && (allAssetsVX.length === addressCheck.length),
    onSuccess(dataVXAssets) {
      setOwnerVXAssets(dataVXAssets)
    },
  })

  useEffect(() => {
    if(ownerVXAssets.length > 0){
      let sum = 0;
      for (let i = 0; i < ownerVXAssets?.length; i++) {
          sum += ownerVXAssets[i].toNumber();
      }
      setTotalOwnedVXAssets(sum)
    }
  }, [ownerVXAssets])

  //console.log(ownerVXAssets)

  // enforce dysto # to be 1 - 2077
  const handleSanity = async (e) => {
    if(e.target.value > 2077) {
      setDystoPunksCheck(2077)
    } else if(e.target.value <= 0){
      setDystoPunksCheck(1)
    } else {
      setDystoPunksCheck(e.target.value)
    }
  }


  return (
    <>
    {mounted && <>{isConnected ? (<>{/* <!-- Dashboard Component -->
<!-- Show only when connected, If not, show "Connect Component" --> */}
<main className="main-info text-center px-3">
  <div className="container">
    <div className="row">

    {/* <!-- Dystolab Menu --> */}
    <div className="col-sm-12 dystolab-buttons">
      <div className="btn-group">

      {/* <!-- Dashboard Component Button --> */}
      <NavLink to="/dashboard" className="btn btn-outline-primary active">Dashboard</NavLink>

      {/* <!-- Desktop Collection Component Button --> */}
      <NavLink to="/collection" className="btn btn-outline-primary d-none d-md-block">Collection</NavLink>

      {/* <!-- Mobile Collection Component Button --> */}
      <NavLink to="/collection" className="btn btn-outline-primary d-md-none">NFTs</NavLink>

      {/* <!-- CrateDex Component Button --> */}
      <NavLink to="/cratedex" className="btn btn-outline-primary">CrateDex</NavLink>
      </div>
    </div>
    <hr />

    <div className="col-lg-4 d-flex">
      <div className="row">

      {/* <!-- My Collection --> */}
      <div className="col-sm-12 mint-bg">
        <p className="dystolab-title">My Collection:
        </p>
        <div className="row">
        <div className="col-sm-6">

        {/* <!-- DystoPunks Balance --> */}
        <p className="text-left"><b className="stats-color">{dystoPunks}</b>

        <br />
        <small>DystoPunks</small>
        </p>
        </div>
        <div className="col-sm-6">

        {/* <!-- DystoPunks VX Balance --> */}
        <p className="text-left"><b className="stats-color">{dystoPunksVX}</b>

        <br />
        <small>DystoPunks VX</small>
        </p>
        </div>
        <hr />
        <div className="col-sm-6">

        {/* <!-- Unopened Crates Balance --> */}
        <p className="text-left"><b className="stats-color">{crates}</b>

        <br />
        <small>Crates</small>
        </p>
        </div>
        <div className="col-sm-6">

        {/* <!-- VX Assets Balance --> */}
        <p className="text-left"><b className="stats-color">
          {totalOwnedVXAssets?.toString()}
          </b>

        <br />
        <small>VX Assets</small>
        </p>
        </div>
        </div>
      </div>

      {/* <!-- Dystolab Web3 DApp --> */}
      <div className="col-sm-12 mint-bg text-left">
        <p className="dystolab-title">Dystolab Web3 DApp 1.0
        </p>
        <img src="img/logos/dystolab.png" alt="Dystolab" className="img-fluid" />
        <hr />

        {/* <!-- Patch Notes Component Button --> */}
        <NavLink to="/patch-notes" className="btn btn-outline-primary">Patch Notes</NavLink>

      </div>

      </div>
    </div>

    <div className="col-lg-4 d-flex">
      <div className="row">

      {/* <!-- Claimable $CREDS --> */}
      <div className="col-sm-12 mint-bg">
        <p className="dystolab-title">Claimable $CREDS:
        </p>
        <div className="row">
        <div className="col-sm-6">

        {/* <!-- Claimable $CREDS Balance --> */}
        <p><b className="stats-color"><img src="img/logos/cred.gif" alt="$CREDS" className="img-fluid mint-icons" />{credsClaimable}</b>

        <br />
        <small>$CREDS</small>
        </p>
        </div>
        <ClaimCreds />
        </div>
      </div>

      {/* <!-- Staking $CREDS --> */}
      <div className="col-sm-12 mint-bg">
        <p className="dystolab-title">Staking $CREDS:
        </p>

        <div className="row">
        <div className="col-sm-12">
        <img src="img/logos/credstack.png" alt="$CREDS" className="img-fluid dystolab-creds" />
        </div>
        <hr />
        <div className="col-sm-6">
        <p><b className="stats-color">77% APR</b>
        <br />
        <small>Coming soon...</small>
        </p>
        </div>

        {/* <!-- Uniswap $CREDS LP --> */}
        <div className="col-sm-6">
        <a href="https://app.uniswap.org/#/swap?inputCurrency=0xc13F4F0F865bAc08F62654B57E38669EbC4747a3&chain=mainnet" target="_blank" rel="noopener noreferrer" className="btn btn-outline-secondary mt-2" type="button">Uniswap</a>
        </div>

        </div>
      </div>

      </div>
    </div>

    <div className="col-lg-4 d-flex">
      <div className="row">

      {/* <!-- DystoPunks Checker --> */}
      <div className="col-sm-12 mint-bg">
        <p className="dystolab-title">DystoPunks Checker:</p>
        <div className="row">
        <div className="col-sm-12">
        <div className="input-group mb-3">

        {/* <!-- DystoPunks $CREDS & VX Check Functions --> */}
        <input onChange={(e) => handleSanity(e)} value={dystoPunksCheck} type="number" min="1" max="2077" maxLength="4" className="form-control" placeholder="DystoPunk #" aria-label="DystoPunk #" aria-describedby="dystopunks-checker" />
        <button onClick={(e) => setPunkToCheck(dystoPunksCheck)} className="btn btn-outline-primary" type="button" id="dystopunks-checker">Check</button>

        </div>
        </div>
        <hr />
        <div className="col-sm-6">

        {/* <!-- Claimable $CREDS Balance --> */}
        <div className="text-left">
          <b className="stats-color"><img src="img/logos/cred.gif" alt="$CREDS" className="img-fluid mint-icons" />
            {dystoPunksCreds}
          </b>
        <br />
        <small>$CREDS</small>
        </div>
        </div>
        <div className="col-sm-6">

        {/* <!-- VX Status --> */}
        <div className="text-left">

          {/* <!-- Default VX Status --> */}
          {!Boolean(punkToCheck) && !isVXClaimedLoading &&<b className="stats-color">Check #</b>}
          
          {/* <!-- Claimed VX --> */}
          {vxClaimed && Boolean(punkToCheck) && !isVXClaimedLoading && <b className="stats-claimed">Claimed</b> }

          {/* <!-- Unclaimed VX --> */}
          {!vxClaimed && Boolean(punkToCheck) && !isVXClaimedLoading && <b className="stats-unclaimed">Unclaimed</b> }
        <br />
        <small>DystoPunk VX</small>
        </div>
        </div>
        </div>
      </div>

    {/* <!-- OpenSea Slugs -->
      <!-- DystoPunks: https://opensea.io/collection/dystopunks -->
      <!-- DystoPunks VX: https://opensea.io/collection/dystopunks-vx --> */}

      {/* <!-- DystoPunks Owners --> */}
      <div className="col-sm-6 mint-bg">
        <p className="dystolab-title">Owners:
        </p>

        {/* <!-- DystoPunks Owners Counter --> */}
        <p className="text-left"><b className="stats-color">{dystoPunksOwners}</b>

        <br />
        <small>DystoPunks</small>
        </p>
        <hr />

        {/* <!-- DystoPunks VX Owners Counter --> */}
        <p className="text-left"><b className="stats-color">{dystoPunksVXOwners}</b>

        <br />
        <small>DystoPunks VX</small>
        </p>
      </div>

      <div className="col-sm-6 mint-bg">
        <p className="dystolab-title">Floor:
        </p>

        {/* <!-- DystoPunks Floor Price --> */}
        <p className="text-left"><b className="stats-color">{v2Floor}Ξ</b>

        <br />
        <small>DystoPunks</small>
        </p>
        <hr />

        {/* <!-- DystoPunks VX Floor Price --> */}
        <p className="text-left"><b className="stats-color">{vxFloor}Ξ</b>

        <br />
        <small>DystoPunks VX</small>
        </p>
      </div>

      </div>
    </div>

    {/* <!-- Mint Info --> */}
    <Mintinfo />
    {/* <!-- Litepaper Info --> */}
    <LitepaperInfo />

    </div>
  </div>
</main></>) : (<Connect />)} </>}
    
    </>
  )
}

export default Dashboard