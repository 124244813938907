import React from 'react'

function CratesInfo({crateBal, uniqueAssets}) {
  return (
    <>
    <div className="col-sm-12 mint-bg">
      <div className="row">
        <div className="col-sm-6 text-left">
        <div className="row">
        <div className="col-lg-2">
        <img src="img/logos/crate.png" alt="Crate" className="img-fluid crate" />
        </div>
        <div className="col-lg-10">
        <p><b className="crate-title">Gotta collect 'em all!</b>
        <br />
        NFT badges, unlockables and collector's prizes coming soon...
        </p>
        </div>
        </div>
        </div>
      <div className="col-sm-6 text-right">
        <div className="row">
        <div className="col-sm-7">

        {/* <!-- Unopened Crates Balance --> */}
        <p><b className="stats-color">{crateBal?.toString()}</b>

        <br />
        <small>Unopened Crates</small>
        </p>
        </div>
        <div className="col-sm-5">

        {/* <!-- VX Assets Balance --> */}
        <p><b className="stats-color">{uniqueAssets?.toString()}/127</b>

        <br />
        <small>Unique VX Assets</small>
        </p>
        </div>
        </div>
      </div>
      </div>
    </div>
    </>
  )
}

export default CratesInfo