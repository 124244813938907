import {React, useEffect} from 'react'
import { NavLink} from "react-router-dom";
import LitepaperInfo from './info/LitepaperInfo';
import DystolabInfo from './info/DystolabInfo';

function PatchNotes() {
  useEffect(() => {
    document.body.classList.add(
      "d-flex",
      "text-bg-dark",
      "dystolab-bg"
    );
    document
      .getElementById("root")
      .classList.add(
        "cover-container",
        "d-flex",
        "w-100",
        "h-100",
        "p-3",
        "mx-auto",
        "flex-column"
      );

    return function cleanup() {
      document.body.classList.remove(
        "d-flex",
        "text-bg-dark",
        "dystolab-bg"
      );
      document
        .getElementById("root")
        .classList.remove(
          "cover-container",
          "d-flex",
          "w-100",
          "h-100",
          "p-3",
          "mx-auto",
          "flex-column"
        );
    };
  }, []);
  return (
    <>
    {/* <!-- Patch Notes Component --> */}
{/* <!-- Show only when connected, If not, show "Connect Component" --> */}
<main className="main-info text-center px-3">
  <div className="container">
    <div className="row">

    <div className="col-sm-12 mint-bg">
      <div className="row">
      <div className="col-sm-6 text-left">
        <img src="img/logos/dystolab.png" alt="Dystolab" className="patch-logo-mobile img-fluid d-md-none" />
        <h2 className="title-primary">Patch Notes</h2>
        <p className="dystolab-title">Dystolab Web3 DApp</p>

        {/* <!-- Dashboard Component Button --> */}
        <NavLink to="/dashboard" className="btn btn-outline-primary"><ion-icon name="arrow-back" class="btn-icon"></ion-icon> Dashboard</NavLink>

      </div>
      <div className="col-md-6 text-right d-none d-md-block">
        <img src="img/logos/dystolab.png" alt="Dystolab" className="patch-logo img-fluid" />
      </div>
      </div>
      <hr />
      <div className="row">

        {/* <!-- Versions Menu --> */}
        <div className="col-lg-2 ver-menu">
          <div className="btn-group-vertical">
          <input type="radio" className="btn-check" name="options-outlined" id="ver.0.1" autoComplete="off" checked />
          <label className="btn btn-patch btn-outline-primary" htmlFor="ver.0.1">Ver. 1.0</label>
          </div>
        </div>

        {/* <!-- Patch Notes 1.0 --> */}
        <div className="col-lg-10 text-left">
          <div className="row">

          {/* <!-- Added --> */}
          <div className="col-sm-12">
            <div className="row">
            <div className="col-sm-12 patch-bg text-left">
            <div className="title-added">
            <h5 className="title"><ion-icon name="add-sharp" class="added-icon"></ion-icon> Added</h5>
            </div>
            </div>
            <div className="col-sm-12 patch-info text-left">
            <div className="added">
            <p className="white-text"><b>Homepage: </b>To introduce DystoPunks.</p>
            </div>
            <div className="added">
            <p className="white-text"><b>Global: </b>Connect your Metamask Wallet and access Web3 ENS profile.</p>
            </div>
            <div className="added">
            <p className="white-text"><b>Mint: </b>Mint DystoPunks VX and burn Dystolab Crates.</p>
            </div>
            <div className="added">
            <p className="white-text"><b>Dashboard: </b>Have access to the important data and main functions.</p>
            </div>
            <div className="added">
            <p className="white-text"><b>Collections: </b>Check your DystoPunks and DystoPunks VX.</p>
            </div>
            <div className="added">
            <p className="white-text"><b>CrateDex: </b>Follow your progression inside your Dystolab VX collection.</p>
            </div>
            <div className="added">
            <p className="white-text"><b>Patch Notes: </b>Every update will be explain by a patch note.</p>
            </div>
            <div className="added">
            <p className="white-text"><b>Litepaper: </b>Contains all the information about the project.</p>
            </div>
            </div>
            </div>
          </div>

          {/* <!-- Updated --> */}
          {/*<div className="col-sm-12">
            <div className="row">
            <div className="col-sm-12 patch-bg text-left">
            <div className="title-added">
            <h5 className="title"><ion-icon name="checkmark-sharp" class="updated-icon"></ion-icon> Updated</h5>
            </div>
            </div>
            <div className="col-sm-12 patch-info text-left">
            <div className="updated">
            <p className="white-text"><b>Lorem ipsum: </b>Dolor sit amet, consectetur adipiscing elit</p>
            </div>
            <div className="updated">
            <p className="white-text"><b>Lorem ipsum: </b>Dolor sit amet, consectetur adipiscing elit</p>
            </div>
            </div>
            </div>
          </div>*/}

          {/* <!-- Removed --> */}
          {/*<div className="col-sm-12">
            <div className="row">
            <div className="col-sm-12 patch-bg text-left">
            <div className="title-added">
            <h5 className="title"><ion-icon name="remove-sharp" class="removed-icon"></ion-icon> Removed</h5>
            </div>
            </div>
            <div className="col-sm-12 patch-info text-left">
            <div className="removed">
            <p className="white-text"><b>Lorem ipsum: </b>Dolor sit amet, consectetur adipiscing elit</p>
            </div>
            <div className="removed">
            <p className="white-text"><b>Lorem ipsum: </b>Dolor sit amet, consectetur adipiscing elit</p>
            </div>
            </div>
            </div>
          </div>*/}

          </div>
        </div>

      </div>
    </div>

    {/* <!-- Mint Info --> */}
    <DystolabInfo />
    {/* <!-- Litepaper Info --> */}
    <LitepaperInfo />
    </div>
  </div>
</main>
    </>
  )
}

export default PatchNotes