import { Canvas, useLoader } from "@react-three/fiber"
import { Environment, useAnimations } from "@react-three/drei"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import {useEffect} from 'react'
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';

function Mascot() {
    const Model = () => {
        // location of the 3D model
        const gltf = useLoader(GLTFLoader, "/img/GLTFs/833.gltf");
        const {actions} = useAnimations(gltf.animations, gltf.scene)
        useEffect(() => {
            actions?.["Idle 01"]?.play();
          }, [actions])
        
        return (
          <>
            {/* Use scale to control the size of the 3D model */}
            <primitive object={gltf.scene} scale={0.1} position={[0,-5.2,0]} rotation={[0, 30, 0]}/>
          </>
        );
      };
      
  return (
      <div className="model">
      <Canvas shadows dpr={[1, 2]}>
        <PerspectiveCamera position={[3.2, -0.8, 0]} fov={[50]} makeDefault/>
          <ambientLight intensity={0.2}/>
          <spotLight intensity={0.2} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow/>
            <Model/>
            <Environment files="img/bank_vault_4k.hdr"/>
            <OrbitControls/>
        </Canvas>
      </div>
  )
}

export default Mascot