import { useState, useEffect } from "react";

export function useAddressArray(address) {
    // State and setters for debounced value
    const [addressArray, setAddressArray] = useState([]);

    useEffect(() => {
      while (addressArray.length < 127) {
        addressArray.push(address)
      }
    }, [address, addressArray])
    
    return addressArray;
  }