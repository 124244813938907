import React from 'react'
import { NavLink} from "react-router-dom";

function Mintinfo() {
  return (
    <>
    {/* <!-- Mint Info --> */}
    <div className="col-sm-12 mint-bg2">
      <p className="lead text-center">To mint <b>DystoPunks VX</b> and burn <img src="img/logos/crate.png" alt="Crate" className="img-fluid mint-icons" /><b>Dystolab Crates</b>, please go to&nbsp;

      {/* <!-- Mint App Button --> */}
      <NavLink className="dystolab" to="/mint"><b>Mint</b></NavLink> section

      </p>
    </div>
    </>
  )
}

export default Mintinfo