import {React, useEffect, useState} from 'react'
import { NavLink} from "react-router-dom";
import { useContractRead, useAccount } from 'wagmi'
import {useAddressArray} from './hooks/useAddressArray'
import DystolabInfo from './info/DystolabInfo';
import LitepaperInfo from './info/LitepaperInfo';
import VXDex from './VXDex';
import CratesInfo from './menus/CratesInfo';
import { useIsMounted } from "./hooks/useIsMounted";
import {crateContract, dystolabContract} from './contracts/allContracts.js'
import { useUniqueSummer } from './hooks/useUniqueSummer';
import Mintinfo from './info/Mintinfo';
import Connect from './Connect';

function CrateDex({crates, assets}) {
  const mounted = useIsMounted();
  const { address, isConnected, isConnecting, isDisconnected } = useAccount()
  const allAssetsVX = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127]
  const addressCheck = useAddressArray(address)
  const [ownerVXAssets, setOwnerVXAssets] = useState([])
  const [crateBal, setCrateBal] = useState("")
  const uniqueAssets = useUniqueSummer(ownerVXAssets)
  
  useEffect(() => {
    document.body.classList.add(
      "d-flex",
      "text-bg-dark",
      "dystolab-bg"
    );
    document
      .getElementById("root")
      .classList.add(
        "cover-container",
        "d-flex",
        "w-100",
        "h-100",
        "p-3",
        "mx-auto",
        "flex-column"
      );

    return function cleanup() {
      document.body.classList.remove(
        "d-flex",
        "text-bg-dark",
        "dystolab-bg"
      );
      document
        .getElementById("root")
        .classList.remove(
          "cover-container",
          "d-flex",
          "w-100",
          "h-100",
          "p-3",
          "mx-auto",
          "flex-column"
        );
    };
  }, []);



  const { data: dataVXAssets, isLoading: isVXAssetsLoading } = useContractRead({
    ...dystolabContract,
    functionName: 'balanceOfBatch',
    args: [addressCheck, allAssetsVX],
    enabled: isConnected && Boolean(addressCheck) && (allAssetsVX.length === addressCheck.length),
    onSuccess(dataVXAssets) {
      setOwnerVXAssets(dataVXAssets)
    },
  })
  const { data: userCrateBal, isLoading: isUserCrateBalLoading } = useContractRead({
    ...crateContract,
    functionName: 'balanceOf',
    args: [address, "77"],
    enabled: isConnected,
    onSuccess(userCrateBal) {
      setCrateBal(userCrateBal)
    },
  })

  // console.log(ownerVXAssets)

  return (
    <>
    {mounted && isConnected ? <>{/* <!-- CrateDex Component --> */}
{/* <!-- Show only when connected, If not, show "Connect Component" --> */}
<main className="main-info text-center px-3">
  <div className="container">
    <div className="row">

    {/* <!-- Dystolab Menu --> */}
    <div className="col-sm-12 dystolab-buttons">
      <div className="btn-group">

      {/* <!-- Dashboard Component Button --> */}
      <NavLink to="/dashboard" className="btn btn-outline-primary">Dashboard</NavLink>

      {/* <!-- Desktop "Collection Component" Button --> */}
      <NavLink to="/collection" className="btn btn-outline-primary d-none d-md-block">Collection</NavLink>

      {/* <!-- Mobile "Collection Component" Button --> */}
      <NavLink to="/collection" className="btn btn-outline-primary d-md-none">NFTs</NavLink>

      {/* <!-- CrateDex Component Button --> */}
      <NavLink to="/cratedex" className="btn btn-outline-primary active">CrateDex</NavLink>
      </div>
    </div>
    <hr />

    {/* <!-- Crates Info --> */}
    <CratesInfo crateBal={crateBal} uniqueAssets={uniqueAssets}/>
    {/* ALL VX Items */}
    <VXDex ownerVXAssets={ownerVXAssets} />
    {/* <!-- Mint Info --> */}
    <Mintinfo />
    {/* <!-- Litepaper Info --> */}
    <LitepaperInfo />

    </div>
  </div>
</main></>:<Connect />}</>
  )
}

export default CrateDex