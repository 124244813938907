import { NavLink } from 'react-router-dom'
import {useEffect} from 'react'
function NotFound() {
    useEffect(() => {
        document.body.classList.add(
          "d-flex",
          "h-100",
          "text-bg-dark",
          "home-bg"
        );
        document
          .getElementById("root")
          .classList.add(
            "cover-container",
            "d-flex",
            "w-100",
            "h-100",
            "p-3",
            "mx-auto",
            "flex-column"
          );
    
        return function cleanup() {
          document.body.classList.remove(
            "d-flex",
            "h-100",
            "text-bg-dark",
            "background"
          );
          document
            .getElementById("root")
            .classList.remove(
              "cover-container",
              "d-flex",
              "w-100",
              "h-100",
              "p-3",
              "mx-auto",
              "flex-column"
            );
        };
      }, []);
  return (
    <>
    <main className="main-info text-center px-3">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h1 className="error404">404</h1>
                    <p className="text-center">Page not found
                    <br/><br/>
                    </p>
                    <NavLink className="btn btn-primary" to="/" type="button">Back to Home</NavLink>
                </div>
            </div>
        </div>
    </main>
    </>
  )
}

export default NotFound