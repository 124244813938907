import React, { useState, useEffect } from 'react';

function DystoPunkVXCard({name, image, tokenId}) {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };
  return (
    <>
      <div className="col-md-2">
        <a href={`https://opensea.io/assets/ethereum/0xf91523bc0ffa151abd971f1b11d2567d4167db3e/${tokenId}`} target="_blank" rel="noopener noreferrer">
          <img src={image} alt="DystoPunk VX" onLoad={handleImageLoad} style={{ display: loaded ? 'block' : 'none' }} className="img-fluid collection" />
        </a>
      </div>
    </>
  )
}

export default DystoPunkVXCard