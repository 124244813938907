import React from 'react'

function PageFooter() {
  return (
    <>
    {/* <!-- Footer Component --> */}
    <footer className="text-center mt-auto text-white-50">
    <p>Built on <a href="https://ethereum.org/" className="" target="_blank" rel="noopener noreferrer">Ethereum</a>, by <a href="https://twitter.com/0xDekadente" className="dekadente" target="_blank" rel="noopener noreferrer">Dekadente</a></p>
    </footer>
    </>
  )
}

export default PageFooter