import { useState } from "react";
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction, useAccount } from "wagmi";

function ApprovalButton({crateContract, dystolabAddress}) {
    const { 
        config,
        error: prepareError,
        isError: isPrepareError,
      } = usePrepareContractWrite({
        ...crateContract,
        functionName: "setApprovalForAll",
        args: [dystolabAddress, "true"],
      });

    const { data, error, isError, write } = useContractWrite(config);

    const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
  });
  return (
    <>
        <button className="btn btn-success" onClick={() => write?.()}>
            {!isLoading && !isSuccess && "Approval"}
            {isLoading && !isSuccess &&
            <button class="btn btn-success btn-loading" type="button">
              <span class="spinner-border spinner-border-sm neo-black" role="status" aria-hidden="true"></span>
              Approving...
            </button>}
            {!isLoading && isSuccess && "Approved!"}
        </button>
    </>
  )
}

export default ApprovalButton